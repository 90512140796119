import React from "react";
import Layout from "../ui/layouts/Layout";

const legal = () => {
  return (
    <div style={{ backgroundColor: "rgba(9, 0, 43, 1)" }}>
      <Layout alwayNavbarVisible style={{ color: "white" }}>
        <div style={{ marginTop: "100px", backgroundColor: "white", padding: "30px" }}>
          <h2>DISCLAIMER</h2>
          <p style={{ textAlign: "justify" }}>

            Last updated December 22, 2020<br/> <br/>

            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
            SERVICES
            WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
            IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES
            OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES
            LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
            <ol>
              <li> ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              </li>
              <li> PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
                OF
                THE SITE,
              </li>
              <li> ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                AND/OR
                FINANCIAL INFORMATION STORED THEREIN,
              </li>
              <li> ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
              </li>
              <li>
                ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY
                THIRD
                PARTY, AND/OR
              </li>
              <li>
                ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
                NOT
                WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY
                A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED
                IN
                ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                MONITORING
                ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
                OF
                A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
                EXERCISE CAUTION WHERE APPROPRIATE.
              </li>
            </ol>
            <br/><br/>
            <b> The Digital BA</b> <br/>
            Grosvenor Place 225 George St<br/>
            Sydney, New South Wales 2000<br/>
            Australia<br/>
            Phone: __________<br/>
            hello@thedigitalba.com.au<br/>

          </p>
        </div>
      </Layout>
    </div>
  );
};

export default legal;
